import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import CommonSection from "../../components/common-section/CommonSection";
import { useDispatch, useSelector } from "react-redux";
import { getNews } from "../../redux/actions/actions";
import { Link } from "react-router-dom";

export default function VpsPro() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { news } = useSelector((state) => state.news);
  const { location } = useSelector((state) => state.location);

  const type = "yangiliklar?last_count=10";

  useEffect(() => {
    dispatch(getNews({ type }));
  }, []);

  return (
    <>
      <CommonSection title={"VPS pro"} breadCrumb={"VPS pro"} />
      <section className="container">
        <div className="flex mx-auto py-6">
          <div className="w-1/4 pr-5 pt-5 lg:hidden">
            <div className="sticky top-20">
              <h3 className="text-2xl font-medium text-logo mb-8">
                {t("categories")}
              </h3>

              <div>
                {news &&
                  news?.result?.map((item, idx) => (
                    <Link
                      className="flex min-h-[90px] pb-5 border-b border-[#ccc] mb-5"
                      to={`/news/${item.id}`}
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      <img
                        className="object-cover block h-[70px]"
                        width={"70"}
                        height={"70"}
                        src={`${item?.files[0]?.file}`}
                        alt="image"
                      />
                      <div className="pl-4">
                        <p className="text-logo hover:text-hover text-xs uppercase mb-2">
                          {location === "uz"
                            ? item?.title_uz?.substring(0, 70)
                            : location === "ru"
                            ? item?.title_ru?.substring(0, 70)
                            : item?.title_en?.substring(0, 70)}
                        </p>
                        <div
                          className="text-logo-secondary hover:text-hover text-xs"
                          dangerouslySetInnerHTML={{
                            __html:
                              location === "uz"
                                ? item?.content_uz?.substring(3, 70)
                                : location === "ru"
                                ? item?.content_ru?.substring(3, 70)
                                : item?.content_en?.substring(3, 70),
                          }}
                        />
                      </div>
                    </Link>
                  ))}
              </div>
            </div>
          </div>

          <div
            className="card w-3/4 lg:w-full pl-10 pt-5 px-5"
            style={{ border: "1px solid #abb8c3" }}
          >
            <h2 className="text-4xl font-bold text-logo mb-16 text-center uppercase">
              VPS pro {t("tarif_plan")}
            </h2>
            <p className="text-lg text-center leading-8 mb-16">
              {t("tarif_text")}
            </p>

            <table className="mr-auto w-full text-lg ml-auto mb-20">
              <thead className="text-left">
                <tr className="border-b border-grey-400">
                  <th className="py-5 pr-20">{t("tarif_table_h1")}</th>
                  <th className="py-5 pl-20">{t("amount")}</th>
                  <th className="py-5 pl-20">{t("cost")}</th>
                </tr>
              </thead>
              <tbody>
                <tr className="border-b border-grey-400">
                  <td className="py-5 pr-20">vCPU</td>
                  <td className="py-5 pl-20">8 yadro</td>
                  <td className="py-5 pl-20">706 496 {t("sum")}</td>
                </tr>
                <tr className="border-b border-grey-400">
                  <td className="py-5 pr-20">RAM</td>
                  <td className="py-5 pl-20">8 GB</td>
                  <td className="py-5 pl-20">96 544 {t("sum")}</td>
                </tr>
                <tr className="border-b border-grey-400">
                  <td className="py-5 pr-20">HDD</td>
                  <td className="py-5 pl-20">500 GB</td>
                  <td className="py-5 pl-20">364 000 {t("sum")}</td>
                </tr>
                <tr className="border-b border-grey-400">
                  <td className="py-5 pr-20">Internet</td>
                  <td className="py-5 pl-20">{t("to10")}</td>
                  <td className="py-5 pl-20">{t("free")}</td>
                </tr>
                <tr className="border-b border-grey-400">
                  <td className="py-5 pr-20">UZ-IX {t("and")} TAS-IX</td>
                  <td className="py-5 pl-20">{t("to100")}</td>
                  <td className="py-5 pl-20">{t("free")}</td>
                </tr>
                <tr className="border-b border-grey-400">
                  <td className="py-5 pr-20">{t("static_ip")}</td>
                  <td className="py-5 pl-20">1</td>
                  <td className="py-5 pl-20">24 000 {t("sum")}</td>
                </tr>
                <tr className="border-b border-grey-400">
                  <td className="py-5 pr-20">{t("total")}</td>
                  <td className="py-5 pl-20"></td>
                  <td className="py-5 pl-20">1 191 040 {t("sum")}</td>
                </tr>
              </tbody>
            </table>

            <div className="mb-20 text-center">
              <a
                target="_blank"
                rel="noreferrer"
                href={"http://xizmatlar.unicon.uz/service/18"}
                className="bg-logo hover:bg-hover transition-all active:opacity-80 text-xl text-white px-10 font-semibold py-4 rounded-lg"
              >
                {t("order")}
              </a>
            </div>

            <h3 className="text-lg font-bold mb-8">{t("unit_formed_title")}</h3>
            <ul className="text-lg pl-6 mb-16">
              <li className="list-disc mb-3 font-medium">
                {t("vps_formed_item_1")}
              </li>
              <li className="list-disc mb-3 font-medium">
                {t("vps_formed_item_2")}
              </li>
              <li className="list-disc mb-3 font-medium">
                {t("vps_formed_item_3")}
              </li>
              <li className="list-disc mb-3 font-medium">
                {t("vps_formed_item_4")}
              </li>
              <li className="list-disc mb-3 font-medium">
                {t("vps_formed_item_5")}
              </li>
            </ul>

            <h3 className="text-lg font-bold mb-8">
              {t("unit_conditions_title")}
            </h3>
            <ul className="text-lg pl-6 mb-16">
              <li className="list-disc mb-3 font-medium">
                {t("vps_conditions_item_1")}
              </li>
              <li className="list-disc mb-3 font-medium">
                {t("vps_conditions_item_2")}
              </li>
              <li className="list-disc mb-3 font-medium">
                {t("vps_conditions_item_3")}
              </li>
              <li className="list-disc mb-3 font-medium">
                {t("vps_conditions_item_4")}
              </li>
              <li className="list-disc mb-3 font-medium">
                {t("vps_conditions_item_5")}
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
}
