import React, { useContext } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Logo from '../../assets/logo.png'
import Mail from '../../assets/mail.svg'
import Phone from '../../assets/phone-icon.svg'
import Location from '../../assets/map-pin.svg'
import Time from '../../assets/clock.svg'
import Facebook from '../../assets/facebook.svg'
import Telegram from '../../assets/telegram.svg'
import Youtube from '../../assets/youtube.svg'
import { useTranslation } from "react-i18next";
import '../../index.css'
import { SystemContext } from "../../context";

export default function Footer() {
  const location1 = useLocation();
  const { t } = useTranslation()
  const { setHasScrollElement } = useContext(SystemContext)

  const handleClick = () => {
    setHasScrollElement(true)
  }

  return (
    <footer className={'bg-logo text-white'}>
      <div className="container mr-auto ml-auto flex justify-between pt-8 pb-8 flex-wrap lg:justify-evenly">
        <ul className={'pl-0'}>
          <li className="mb-5"><a href="/"><img width={'260'} src={Logo} alt="site logo" /></a></li>
          <li className="mb-4 flex w-72"><img className='mr-3' src={Location} alt="location icon" />
            <address>{t('address_footer')}</address>
          </li>
          <li className="mb-4 flex hover:opacity-80 active:opacity-60"><img className='mr-3' src={Phone}
            alt="phone icon" /><a href="tel">+998 71 208
              81 94</a></li>
          <li className="mb-4 flex"><img className='mr-3' src={Time} alt="time icon" />
            <time data-time={'9:00-18:00'}>{t('work_day')}</time>
          </li>
        </ul>

        <ul className="">
          <li className="text-2xl mb-5 uppercase"><h3>{t('links')}</h3></li>

          <li className="hover:opacity-80 active:opacity-60 mb-4 uppercase"><Link to={'/about-us'}
            onClick={() => window.scrollTo(0, 0)}>{t('about')}</Link>
          </li>

          <li className="hover:opacity-80 active:opacity-60 mb-4 uppercase">
            <a href='/news' onClick={handleClick}>{t('news')}</a>
          </li>

          <li className="hover:opacity-80 active:opacity-60 mb-4 uppercase">
            <a href="/services" onClick={handleClick}>{t('servises')}</a>
          </li>

          <li className="hover:opacity-80 active:opacity-60 mb-4 uppercase"><Link to={'/connection'}
            onClick={() => window.scrollTo(0, 0)}>{t('contact')}</Link>
          </li>
        </ul>


        <ul className="">
          <li className="text-2xl mb-5 uppercase"><h3>{t('servis')}</h3></li>
          <li className="mb-4">{t('tec-support')}</li>
          <li className="mb-4 flex hover:opacity-80 active:opacity-60"><img className='mr-3' src={Phone}
            alt="phone icon" /> <a
              href="tel:+998712080712">+998712080712</a></li>
          <li className="mb-4 flex hover:opacity-80 active:opacity-60"><img className='mr-3' src={Mail}
            alt="mail icon" /> <a
              href="mailto:support@unicon.uz">SUPPORT@UNICON.UZ</a></li>
        </ul>


        <ul className="">
          <li className="text-2xl mb-5 uppercase"><h3>{t('contact')}</h3></li>
          <li className="mb-4">{t('clients')}</li>
          <li className="mb-4 flex hover:opacity-80 active:opacity-60"><img className='mr-3' src={Phone}
            alt="phone icon" /> <a
              href="tel:+998999202440">+998999202440</a></li>
          <li className="mb-4 flex hover:opacity-80 active:opacity-60"><img className='mr-3' src={Phone}
            alt="phone icon" /> <a
              href="tel:+998994816699">+998994816699</a></li>
          {/* <li className="mb-4 flex hover:opacity-80 active:opacity-60"><img className='mr-3' src={Mail}
            alt="mail icon" /> <a
              href="mailto:contract@unicon.uz">CONTRACT@UNICON.UZ</a></li> */}
        </ul>
      </div>
      <div className='container flex flex-col'>
        <hr className='border-white mb-4' />
        <div className='flex justify-between mb-5'>
          <p>2023 © UNICON.UZ {t('copyright')}</p>
          <div className='flex gap-4'>
            <a href="https://metrika.yandex.ru/stat/?id=93396223&amp;from=informer"
              target="_blank" rel="noreferrer"><img src="https://informer.yandex.ru/informer/93396223/3_1_FFFFFFFF_EFEFEFFF_0_pageviews"
                style={{ width: "88px", height: "31px", border: 0 }} alt="Яндекс.Метрика" title="Яндекс.Метрика: данные за сегодня (просмотры, визиты и уникальные посетители)" className="ym-advanced-informer" data-cid="93396223" data-lang="ru" /></a>
            <ul className='flex gap-3'>
              <li className='hover:opacity-80 active:opacity-60'><a href="#"><img src={Facebook}
                alt="facebook icon" /></a></li>
              <li className='hover:opacity-80 active:opacity-60'><a href="#"><img src={Telegram}
                alt="telegram icon" /></a></li>
              <li className='hover:opacity-80 active:opacity-60'><a href="#"><img src={Youtube} alt="youtube icon" /></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  )
}
