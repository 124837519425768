import { SWITCH_LANG, GET_ELEMENT, TYPING, INITIAL_STATE, GET_NEWS, GET_SINGLE_NEWS, GET_DOCS, GET_DATA_CENTER_LIST } from "../constants/constants";

const lang = {
    location: localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') : "uz"
}
const initialStateElement = {
  element: {},
}
const initialStateInput = {
  inputs: {}
}

const initialStateNews = {
  news: [],
  singleNew: {},
  loading: false,
};

const initialStateDocs = {
  docs: [],
  loading: false,
}; 

const initialStateAddress = {
  dc_address: [],
  loading: false,
};
  

export const langReducer = (state = lang, action) => {
    switch (action.type) {
      case SWITCH_LANG:
        return {
          ...state,
          location: action.payload
        }
      default:
        return state
    }
}

export const changeReducer = (state = initialStateInput, action) => {
  const {type, payload} = action
  switch(type) {
    case TYPING:
      return {
        inputs: {
          ...state.inputs,
          [payload.name]: payload.value
        },
      }
    case INITIAL_STATE: 
      return initialStateInput
    default: 
      return state
  }
}

export const elementReducer = (state = initialStateElement, action) => {
  switch (action.type) {
    case GET_ELEMENT:
      return {
        ...state,
        element: action.payload,
      }
    default:
      return state
  }
}

export const newsReducer = (state = initialStateNews, action) => {
  switch (action.type) {
    case GET_NEWS:
      return {
        ...state,
        news: action.payload,
        loading: false,
      };
    case GET_SINGLE_NEWS:
      return {
        ...state,
        singleNew: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export const docsReducer = (state = initialStateDocs, action) => {
  switch (action.type) {
    case GET_DOCS:
      return {
        ...state,
        docs: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export const dataCenterListReducer = (state = initialStateAddress, action) => {
  switch (action.type) {
    case GET_DATA_CENTER_LIST:
      return {
        ...state,
        dc_address: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
