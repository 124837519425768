import React, { useState } from "react";
import CommonSection from "../../components/common-section/CommonSection";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getDocuments } from "../../redux/actions/actions";
import VideoRekModal from "../../components/modal/videoRekModal";
// import Image from '../../assets/no-results-bg.2d2c6ee3.png'

export default function Docs() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location1 = useLocation();

  const year = new Date().getFullYear();

  const [stateYear, setStateYear] = useState(year.toString());

  const [openIndex, setOpenIndex] = useState(null);

  const { location } = useSelector((state) => state.location);
  const { docs } = useSelector((state) => state.docs);

  useEffect(() => {
    dispatch(getDocuments());
  }, [location1, dispatch]);

  const handleToggle = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <section className="">
      <CommonSection
        title={
          location === "uz"
            ? "Hujjatlar"
            : location === "ru"
            ? "Документы"
            : "Documents"
        }
      />

      <div className="container mx-auto">
        <div className="py-5">
          {docs?.length === 0 ? (
            <div className={"mx-auto w-full text-center"}>
              <img src={Image} alt="" className={"w-1/4 mx-auto"} />
              <h1 className={"text-3xl"}>{t("soon")}</h1>
            </div>
          ) : (
            docs &&
            docs?.map((item, index) => (
              <div key={index} onClick={() => handleToggle(index)}>
                <div
                  className={`px-2 py-4 mt-2 cursor-pointer flex items-center justify-between ${
                    openIndex === index
                      ? "bg-logo text-white"
                      : "bg-bd text-dark"
                  } duration-200`}
                >
                  <div
                    className={""}
                    dangerouslySetInnerHTML={{
                      __html:
                        location === "uz"
                          ? item?.title_uz
                          : location === "ru"
                          ? item?.title_ru
                          : item.title_en,
                    }}
                  />
                  {openIndex === index ? (
                    <h1 className={"text-3xl"}>-</h1>
                  ) : (
                    <h1 className={"text-3xl"}>+</h1>
                  )}
                </div>
                {openIndex === index && (
                  <div
                    className={"px-4 py-2 w-[99%] m-auto flex flex-col gap-2"}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          location === "uz"
                            ? item?.content_uz
                            : location === "ru"
                            ? item?.content_ru
                            : item?.content_en,
                      }}
                    />
                    <a
                      rel="noreferrer"
                      title="yuklash"
                      className="ml-auto px-4 py-2 mt-3 rounded-[4px] bg-[#0E0E4B] text-white xs:px-2 xs:py-1 justify-center duration-200 opacity-70 hover:opacity-100"
                      href={item.file}
                      target={"_blank"}
                    >
                      {t("download")}
                    </a>
                  </div>
                )}
              </div>
            ))
          )}
        </div>
      </div>
      <VideoRekModal />
    </section>
  );
}
