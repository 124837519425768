import {create} from 'zustand';

const useVpsCalcStore = create((set) => ({
  calcVps: {
    tariff: null,
    storage_type: 'hdd',
    storage_disk: null,
    spu: null,
    ram: null,
    internet: null,
    tasix: null,
    count_vm: 1,
    operation_system_versions: '9fd82011-4394-4875-be9d-929aa9dacbbc',
  },
  editStatus: (payload) =>
    set((state) => ({ calcVps: { ...state.calcVps, ...payload } })),
}));
export default useVpsCalcStore; 