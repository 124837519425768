export const SWITCH_LANG = 'SWITCH_LANG'
export const SEND_CONTACT = 'SEND_CONTACT'
export const SEND_ELEMENTS = 'SEND_ELEMENTS'
export const GET_ELEMENT = 'GET_ELEMENT'
export const TYPING = 'TYPING'
export const INITIAL_STATE = "INITIAL_STATE"
export const GET_NEWS = "GET_NEWS"
export const GET_SINGLE_NEWS = "GET_SINGLE_NEWS"
export const GET_DOCS = "GET_DOCS"
export const GET_DATA_CENTER_LIST = "GET_DATA_CENTER_LIST"
