import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./style.css";

export default function Tariffs() {
  const { t } = useTranslation();
  const [open, setOpen] = useState("vps");

  const data = [
    {
      id: 1,
      title: "UNIT-1",
      unit: `1 ${t("piece")}`,
      power: "450V",
      odf: `1 ${t("piece")}`,
      ups: `${t("free")}`,
      to: "/unit",
    },
    {
      id: 2,
      title: "RACK-1",
      unit: `42 ${t("piece")}`,
      power: "6.5KV",
      odf: `1 ${t("piece")}`,
      ups: `${t("free")}`,
      to: "/rack",
    },
    {
      id: 3,
      title: "SELECT",
      unit: `${t("is_selected")}`,
      power: `${t("is_selected")}`,
      odf: `${t("is_selected")}`,
      ups: `${t("free")}`,
      to: "/select",
    },
  ];

  return (
    <section className="pt-20">
      <div className="container">
        <h2 className="text-4xl text-logo font-bold text-center mb-8">
          {t("tarif-plans")}
        </h2>
        <p className="text-center mb-16">
          {t("tarif-plan--text")}
          <br />
          {t("tarif-plan--text2")}
        </p>

        <div className="flex mt-5 justify-center items-center mb-10">
          <Link
            className={
              open == "vps" ? "get_key_btn get_key_btn--active" : "get_key_btn"
            }
            onClick={() => setOpen("vps")}
            href="#"
          >
            VPS
          </Link>
          <Link
            className={
              open == "co" ? "get_key_btn get_key_btn--active" : "get_key_btn"
            }
            onClick={() => setOpen("co")}
            href="#"
          >
            Co-location
          </Link>
          {/* <Link className={open == "hosting" ? "get_key_btn get_key_btn--active" : "get_key_btn"} onClick={() => setOpen("hosting")} href="#">Hosting</Link> */}
        </div>
        <div
          className={
            open != "co" ? "hidden" : "flex justify-between mb-16 flex-wrap"
          }
        >
          {data.map((e, key) => (
            <div
              key={e.id}
              className="w-[400px] shadow-2xl mb-5 pt-12 pb-0 rounded-2xl"
            >
              <dl>
                <dt className="text-hover text-center uppercase mb-5">
                  {t("tarif")}
                </dt>
                <dd className="text-center text-logo font-bold text-4xl mb-10">
                  {e.title}
                </dd>
              </dl>

              <div className="flex flex-wrap mb-5 px-5">
                <div className="w-1/2">
                  <div className=" mb-5 flex tarif-info tarif-info__server">
                    <dl>
                      <dt>Unit soni:</dt>
                      <dd className="font-bold">{e.unit}</dd>
                    </dl>
                  </div>
                  <div className=" mb-5 flex tarif-info tarif-info__lightning">
                    <dl>
                      <dt>Quvvati</dt>
                      <dd className="font-bold">{e.power}</dd>
                    </dl>
                  </div>
                </div>
                <div className="w-1/2">
                  <div className=" mb-5 flex tarif-info tarif-info__folder">
                    <dl>
                      <dt>ODF port</dt>
                      <dd className="font-bold">{e.odf}</dd>
                    </dl>
                  </div>
                  <div className="mb-5 flex tarif-info tarif-info__power">
                    <dl>
                      <dt>UPS </dt>
                      <dd className="font-bold">{e.ups}</dd>
                    </dl>
                  </div>
                </div>
              </div>

              <div className="relative flex justify-center">
                {/* <div className="coming_soon absolute -top-24 -left-20">
                  <p className="w-80 z-50 rounded-md bg-[wheat] p-2">{t("coming_soon_colocation")}</p>
                  <div className="translate-y-[95px] translate-x-[170px] rotate-[50deg] border-l-[30px] border-solid border-[wheat]">.</div>
                </div> */}
                <a
                  // title={t("coming_soon_colocation")}
                  target='_blank'
                  rel="noreferrer"
                  href='http://xizmatlar.unicon.uz/service/1'
                  className="order_btn border-2 border-logo bg-logo hover:bg-hover transition-all active:opacity-80 text-white w-44 text-center font-semibold py-3 rounded-full mr-3 uppercase"
                >
                  {t("order")}
                </a>
                <Link
                  // title={e.to === "/select" ? "Tez orada ishga tushadi." : ""}
                  to={e.to}
                  className="border-2 border-logo hover:opacity-70 active:opacity-60 transition-all text-logo w-44 text-center font-semibold py-3 rounded-full uppercase"
                >
                  {t("more")}
                </Link>
              </div>

              <div className="bg-grey-400 text-white mt-8 text-center rounded-b-2xl">
                <p className="p-2">
                  {e.to == "/select" ? t("demand") : t("agreed")}
                </p>
              </div>
            </div>
          ))}
        </div>

        <div
          className={
            open != "vps" ? "hidden" : "flex justify-between mb-16 flex-wrap"
          }
        >
          <div className="w-[400px] shadow-2xl mb-5 pt-12 rounded-2xl overflow-hidden">
            <dl>
              <dt className="text-hover text-center uppercase mb-5">
                {t("tarif")}
              </dt>
              <dd className="text-center text-logo font-bold text-4xl mb-10">
                VPS STANDART
              </dd>
            </dl>

            <div className="flex flex-wrap mb-5 px-5">
              <div className="w-1/2">
                <div className=" mb-5 flex tarif-info tarif-info__cpu">
                  <dl>
                    <dt>CPU:</dt>
                    <dd className="font-bold">1</dd>
                  </dl>
                </div>
                <div className="mb-5 flex tarif-info tarif-info__ram">
                  <dl>
                    <dt>RAM:</dt>
                    <dd className="font-bold">1 GB</dd>
                  </dl>
                </div>
              </div>
              <div className="w-1/2">
                <div className="mb-5 flex tarif-info tarif-info__memory">
                  <dl>
                    <dt>Disk SSD</dt>
                    <dd className="font-bold">25 GB</dd>
                  </dl>
                </div>
                <div className="mb-5 flex tarif-info tarif-info__internet">
                  <dl>
                    <dt>Internet</dt>
                    <dd className="font-bold">{t("free")}</dd>
                  </dl>
                </div>
              </div>
            </div>

            <div className="flex justify-center">
              <a
                // title={t("coming_soon_vps")}
                target="_blank"
                rel="noreferrer"
                href="http://xizmatlar.unicon.uz/service/18"
                className="border-2 border-logo bg-logo hover:bg-hover transition-all active:opacity-80 text-white w-44 text-center font-semibold py-3 rounded-full mr-3 uppercase"
              >
                {t("order")}
              </a>
              <Link
                to={"/vps-standart"}
                className="border-2 border-logo hover:opacity-70 active:opacity-60 transition-all text-logo w-44 text-center font-semibold py-3 rounded-full uppercase"
              >
                {t("more")}
              </Link>
            </div>

            <div className="bg-grey-400 text-white mt-8 text-center">
              <p className="p-2">184 580 {t("sum")}</p>
            </div>
          </div>

          <div className="w-[400px] shadow-2xl mb-5 pt-12 rounded-2xl overflow-hidden">
            <dl>
              <dt className="text-hover text-center uppercase mb-5">
                {t("tarif")}
              </dt>
              <dd className="text-center text-logo font-bold text-4xl mb-10">
                VPS PRO
              </dd>
            </dl>

            <div className="flex flex-wrap mb-5 px-5">
              <div className="w-1/2">
                <div className="mb-5 flex tarif-info tarif-info__cpu">
                  <dl>
                    <dt>CPU:</dt>
                    <dd className="font-bold">8</dd>
                  </dl>
                </div>
                <div className="mb-5 flex tarif-info tarif-info__ram">
                  <dl>
                    <dt>RAM:</dt>
                    <dd className="font-bold">8 GB</dd>
                  </dl>
                </div>
              </div>
              <div className="w-1/2">
                <div className="mb-5 flex tarif-info tarif-info__memory">
                  <dl>
                    <dt>Disk SSD</dt>
                    <dd className="font-bold">500 GB</dd>
                  </dl>
                </div>
                <div className="mb-5 flex tarif-info tarif-info__internet">
                  <dl>
                    <dt>Internet</dt>
                    <dd className="font-bold">{t("free")}</dd>
                  </dl>
                </div>
              </div>
            </div>

            <div className="flex justify-center">
              <a
                // title={t("coming_soon_vps")}
                target="_blank"
                rel="noreferrer"
                href="http://xizmatlar.unicon.uz/service/18"
                className="border-2 border-logo bg-logo hover:bg-hover transition-all active:opacity-80 text-white w-44 text-center font-semibold py-3 rounded-full mr-3 uppercase"
              >
                {t("order")}
              </a>
              <Link
                to={"/vps-pro"}
                className="border-2 border-logo hover:opacity-70 active:opacity-60 transition-all text-logo w-44 text-center font-semibold py-3 rounded-full uppercase"
              >
                {t("more")}
              </Link>
            </div>

            <div className="bg-grey-400 text-white mt-8 text-center">
              <p className="p-2">1 191 040 {t("sum")}</p>
            </div>
          </div>

          <div className="w-[400px] shadow-2xl mb-5 pt-12 rounded-2xl overflow-hidden">
            <dl>
              <dt className="text-hover text-center uppercase mb-5">
                {t("tarif")}
              </dt>
              <dd className="text-center text-logo font-bold text-4xl mb-10">
                VPS SELECT
              </dd>
            </dl>

            <div className="flex flex-wrap mb-5 px-5">
              <div className="w-1/2">
                <div className="mb-5 flex tarif-info tarif-info__cpu">
                  <dl>
                    <dt>CPU:</dt>
                    <dd className="font-bold">{t("is_selected")}</dd>
                  </dl>
                </div>
                <div className="mb-5 flex tarif-info tarif-info__ram">
                  <dl>
                    <dt>RAM:</dt>
                    <dd className="font-bold">{t("is_selected")}</dd>
                  </dl>
                </div>
              </div>
              <div className="w-1/2">
                <div className="mb-5 flex tarif-info tarif-info__memory">
                  <dl>
                    <dt>Disk SSD</dt>
                    <dd className="font-bold">{t("is_selected")}</dd>
                  </dl>
                </div>
                <div className="mb-5 flex tarif-info tarif-info__internet">
                  <dl>
                    <dt>Internet</dt>
                    <dd className="font-bold">{t("is_selected")}</dd>
                  </dl>
                </div>
              </div>
            </div>

            <div className="flex justify-center">
              <a
                // title={t("coming_soon_vps")}
                target="_blank"
                rel="noreferrer"
                href="http://xizmatlar.unicon.uz/service/18"
                className="border-2 border-logo bg-logo hover:bg-hover transition-all active:opacity-80 text-white w-44 text-center font-semibold py-3 rounded-full mr-3 uppercase"
              >
                {t("order")}
              </a>
              <Link
                title="Tez orada ishga tushadi."
                to={"/vps-select"}
                className="border-2 border-logo hover:opacity-70 active:opacity-60 transition-all text-logo w-44 text-center font-semibold py-3 rounded-full uppercase"
              >
                {t("more")}
              </Link>
            </div>

            <div className="bg-grey-400 text-white mt-8 text-center">
              <p className="p-2">{t("demand")}</p>
            </div>
          </div>
        </div>

        <div
          className={
            open != "hosting"
              ? "hidden"
              : "flex justify-between mb-16 flex-wrap"
          }
        >
          <div className="w-[400px] shadow-2xl mb-5 pt-12 rounded-2xl overflow-hidden">
            <dl>
              <dt className="text-hover text-center uppercase mb-5">
                {t("tarif")}
              </dt>
              <dd className="text-center text-logo font-bold text-4xl mb-10">
                HOST-500
              </dd>
            </dl>

            <div className="flex flex-wrap px-5  mb-5">
              <div className="w-1/2">
                <div className="mb-5 flex tarif-info tarif-info__memory">
                  <dl>
                    <dt>SSD:</dt>
                    <dd className="font-bold">500 MB</dd>
                  </dl>
                </div>
                <div className="mb-5 flex tarif-info tarif-info__site">
                  <dl>
                    <dt>Site:</dt>
                    <dd className="font-bold">1</dd>
                  </dl>
                </div>
              </div>
              <div className="w-1/2">
                <div className="mb-5 flex tarif-info tarif-info__db">
                  <dl>
                    <dt>MySQL:</dt>
                    <dd className="font-bold">1</dd>
                  </dl>
                </div>
                <div className="mb-5 flex tarif-info tarif-info__mail">
                  <dl>
                    <dt>Mailbox</dt>
                    <dd className="font-bold">1</dd>
                  </dl>
                </div>
              </div>
            </div>

            <div className="flex justify-center">
              <a
                target="_blank"
                rel="noreferrer"
                href="http://xizmatlar.unicon.uz"
                className="border-2 border-logo bg-logo hover:bg-hover transition-all active:opacity-80 text-white w-44 text-center font-semibold py-3 rounded-full mr-3 uppercase"
              >
                {t("order")}
              </a>
              <Link
                to={"/host-500"}
                className="border-2 border-logo hover:opacity-70 active:opacity-60 transition-all text-logo w-44 text-center font-semibold py-3 rounded-full uppercase"
              >
                {t("more")}
              </Link>
            </div>

            <div className="bg-grey-400 text-white mt-8 text-center">
              <p className="p-2">{t("agreed")}</p>
            </div>
          </div>

          <div className="w-[400px] shadow-2xl mb-5 pt-12 rounded-2xl overflow-hidden">
            <dl>
              <dt className="text-hover text-center uppercase mb-5">
                {t("tarif")}
              </dt>
              <dd className="text-center text-logo font-bold text-4xl mb-10">
                HOST-1000
              </dd>
            </dl>

            <div className="flex flex-wrap mb-5 px-5">
              <div className="w-1/2">
                <div className="mb-5 flex tarif-info tarif-info__memory">
                  <dl>
                    <dt>SSD:</dt>
                    <dd className="font-bold">1000 MB</dd>
                  </dl>
                </div>
                <div className="mb-5 flex tarif-info tarif-info__site">
                  <dl>
                    <dt>Site:</dt>
                    <dd className="font-bold">3</dd>
                  </dl>
                </div>
              </div>
              <div className="w-1/2">
                <div className="mb-5 flex tarif-info tarif-info__db">
                  <dl>
                    <dt>MySQL:</dt>
                    <dd className="font-bold">3</dd>
                  </dl>
                </div>
                <div className="mb-5 flex tarif-info tarif-info__mail">
                  <dl>
                    <dt>Mailbox:</dt>
                    <dd className="font-bold">3</dd>
                  </dl>
                </div>
              </div>
            </div>

            <div className="flex justify-center">
              <a
                target="_blank"
                href="http://xizmatlar.unicon.uz"
                rel="noreferrer"
                className="border-2 border-logo bg-logo hover:bg-hover transition-all active:opacity-80 text-white w-44 text-center font-semibold py-3 rounded-full mr-3 uppercase"
              >
                {t("order")}
              </a>
              <Link
                to={"/host-1000"}
                className="border-2 border-logo hover:opacity-70 active:opacity-60 transition-all text-logo w-44 text-center font-semibold py-3 rounded-full uppercase"
              >
                {t("more")}
              </Link>
            </div>

            <div className="bg-grey-400 text-white mt-8 text-center">
              <p className="p-2">{t("agreed")}</p>
            </div>
          </div>

          <div className="w-[400px] shadow-2xl mb-5 pt-12 rounded-2xl overflow-hidden">
            <dl>
              <dt className="text-hover text-center uppercase mb-5">
                {t("tarif")}
              </dt>
              <dd className="text-center text-logo font-bold text-4xl mb-10">
                HOST-SELECT
              </dd>
            </dl>

            <div className="flex flex-wrap px-5 mb-5">
              <div className="w-1/2">
                <div className="mb-5 flex tarif-info tarif-info__memory">
                  <dl>
                    <dt>SSD:</dt>
                    <dd className="font-bold">{t("is_selected")}</dd>
                  </dl>
                </div>
                <div className="mb-5 flex tarif-info tarif-info__site">
                  <dl>
                    <dt>Site:</dt>
                    <dd className="font-bold">{t("is_selected")}</dd>
                  </dl>
                </div>
              </div>
              <div className="w-1/2">
                <div className="mb-5 flex tarif-info tarif-info__db">
                  <dl>
                    <dt>MySQL</dt>
                    <dd className="font-bold">{t("is_selected")}</dd>
                  </dl>
                </div>
                <div className="mb-5 flex tarif-info tarif-info__mail">
                  <dl>
                    <dt>Mailbox</dt>
                    <dd className="font-bold">{t("is_selected")}</dd>
                  </dl>
                </div>
              </div>
            </div>

            <div className="flex justify-center">
              <a
                target="_blank"
                rel="noreferrer"
                href="http://xizmatlar.unicon.uz"
                className="border-2 border-logo bg-logo hover:bg-hover transition-all active:opacity-80 text-white w-44 text-center font-semibold py-3 rounded-full mr-3 uppercase"
              >
                {t("order")}
              </a>
              <Link
                to={"/host-2000"}
                className="border-2 border-logo hover:opacity-70 active:opacity-60 transition-all text-logo w-44 text-center font-semibold py-3 rounded-full uppercase"
              >
                {t("more")}
              </Link>
            </div>

            <div className="bg-grey-400 text-white mt-8 text-center">
              <p className="p-2">{t("demand")}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
