import React, { useContext, useEffect } from "react";
import Advantages from "./components/advantages";
import Counter from "./components/counter";
import News from "./components/news";
import Tariffs from "./components/tariffs";
import Hero from "./components/hero";
import ConnectionSection from "../../components/connection/index";
import { SystemContext } from "../../context";
import Videorolik from "./components/videorolik";
import RekModal from "../../components/modal/rekModal";

export default function Home() {
  const { hasScrollElement } = useContext(SystemContext);

  useEffect(() => {
    let element = document.getElementById("news");
    let rect = element.getBoundingClientRect();
    let distance_from_top = rect.top; /* 50px */

    function scrollTovView() {
      window.scrollTo({
        top: distance_from_top,
        behavior: "smooth",
      });
    }
    if (hasScrollElement) {
      scrollTovView();
    }
  }, []);

  return (
    <div className="flex-grow">
      <Hero />
      <Tariffs />
      <div className="container"><Videorolik bdrs={"15px"} /></div>
      {/* <CoLocation /> */}
      <Advantages />
      <News />
      <Counter />
      <ConnectionSection />
      <RekModal />
    </div>
  );
}
