import React from 'react'
import { Link } from 'react-router-dom'
import HeroVideo from '../../../../assets/data-center-at-night.mp4'
import {useTranslation} from "react-i18next";
import './index.css'

export default function Hero() {
  const {t} = useTranslation()

  return (
    <section className='hero bg-logo relative overflow-hidden h-[100vh]' >
        <video className='h-screen w-full object-cover' loop={true} autoPlay={true} muted >
          <source className='h-screen' src={HeroVideo} type="video/mp4" />
        </video>
        
        <div className='w-full absolute z-40 top-0 h-screen hero-bg'>
          <div className='container pb-40 pt-72 lg:pt-48 h-screen flex flex-col items-start'>
              <h1 className='text-5xl md:text-3xl font-semibold mt-auto mb-10 text-white'>{t('duk')}<br/> <span className='text-hover'>{t('dc-site')}</span> <br/>{t('dc-welcome')}</h1>
              <Link to={'/about-us'} className='hover:bg-hover transition-all active:opacity-80 text-white px-8 py-4 md:px-6 md:py-3 my-5 font-semibold  rounded-full border-2 border-hover hover:border-white border-solid text-xl md:text-lg uppercase'>{t('more')}</Link>
          </div>
        </div>
    </section>
  )
}
