import {create} from 'zustand';

const useCalcStore = create((set) => ({
  calcData: [
    {
      amount: 1,
      data_center: "48339358-48f2-45e9-957a-8c604c7b2c1f",
      mounting_type: "RACK",
      status: 1
    },
    {
      amount: 1,
      data_center: "48339358-48f2-45e9-957a-8c604c7b2c1f",
      mounting_type: "UNIT",
      status: 1
    },
  ],
  editStatus: (payload) =>
    set((state) => ({ calcData: { ...state.calcData, ...payload } })),
}));
export default useCalcStore; 