import React, { useEffect } from "react";
import CommonSection from "../../components/common-section/CommonSection";
import { useTranslation } from "react-i18next";
import Videorolik from "../Home/components/videorolik";
import { useDispatch, useSelector } from "react-redux";
import { getNews } from "../../redux/actions/actions";
import { Link } from "react-router-dom";
import VideoRekModal from "../../components/modal/videoRekModal";

export default function AboutUs() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { news } = useSelector((state) => state.news);
  const { location } = useSelector((state) => state.location);

  const type = "yangiliklar?last_count=10";

  useEffect(() => {
    dispatch(getNews({ type }));
  }, []);

  return (
    <>
      <CommonSection title={t("about")} breadCrumb={t("about")} />
      <div className="container flex mx-auto py-6">
        <div className="w-1/4 pr-5 lg:hidden">
          <div className="sticky top-20">
            <h3 className="text-2xl font-medium text-logo mb-8">{t("news")}</h3>

            <div>
              {news &&
                news?.result?.map((item, idx) => (
                  <Link
                    id={idx}
                    className="flex min-h-[90px] pb-5 border-b border-[#ccc] mb-5"
                    to={`/news/${item.id}`}
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    <img
                      className="object-cover block h-[70px]"
                      width={"70"}
                      height={"70"}
                      src={`${item?.files[0]?.file}`}
                      alt="img"
                    />
                    <div className="pl-4">
                      <p className="text-logo hover:text-hover text-xs uppercase mb-2">
                        {location === "uz"
                          ? item?.title_uz?.substring(0, 70)
                          : location === "ru"
                          ? item?.title_ru?.substring(0, 70)
                          : item?.title_en?.substring(0, 70)}
                      </p>
                      <div
                        className="text-logo-secondary hover:text-hover text-xs"
                        dangerouslySetInnerHTML={{
                          __html:
                            location === "uz"
                              ? item?.content_uz?.substring(3, 70)
                              : location === "ru"
                              ? item?.content_ru?.substring(3, 70)
                              : item?.content_en?.substring(3, 70),
                        }}
                      />
                    </div>
                  </Link>
                ))}
            </div>
          </div>
        </div>
        <div
          className="card w-3/4 lg:w-full"
          style={{ border: "1px solid #abb8c3" }}
        >
          <Videorolik bdrs={0} />
          <div className="container pb-40">
            <p className="text-base mb-5 leading-10">{t("about_text_2-new")}</p>
            <p className="text-base mb-5 leading-10">{t("about_text_3-new")}</p>
            <p className="text-base mb-5 leading-10">{t("about_text_4-new")}</p>
            <p className="text-base mb-5 leading-10">{t("about_text_5-new")}</p>
            <p className="text-base mb-5 leading-10">{t("about_text_6-new")}</p>
          </div>
        </div>
      </div>
      <VideoRekModal />
    </>
  );
}
