import React from 'react'
import {ReactComponent as ShutDown} from '../../../../assets/advantages/shur-down.svg'
import Secure from '../../../../assets/advantages/secure.svg'
import Lamp from '../../../../assets/advantages/lamp.svg'
import Service from '../../../../assets/advantages/service.svg'
import Globus from '../../../../assets/advantages/globus.svg'
import Control from '../../../../assets/advantages/control.svg'
import {useTranslation} from "react-i18next";
import './index.css'

export default function Advantages() {
  const {t} = useTranslation()

  const card_data = [
    {src: '/static/media/shut-down.2c671c3b727d8f381198.svg', title: 'UPS', title_text: `${t('ups_text')}`, describtion: `${t('ups_context')}`},
    {src: '/static/media/secure.c93a9db9501b978e4fd7a823d095ecb5.svg', title: `${t('secure_title')}`, title_text: `${t('secure_text')}`, describtion: `${t('secure_context')}`},
    {src: '/static/media/lamp.3b649fe77193b90e8025255c65f797c1.svg', title: `${t('electr_title')}`, title_text: `${t('electr_text')}`, describtion: `${t('electr_context')}`},
    {src: '/static/media/service.2e59bbe10516cd34ce59aacaba77ebae.svg', title: `${t('tex_title')}`, title_text: `${t('tex_text')}`, describtion: `${t('tex_context')}`},
    {src: '/static/media/globus.57727a898b1e7817c99e356bf921d134.svg', title: `${t('infro_title')}`, title_text: `${t('infro_text')}`, describtion: `${t('infro_context')}`},
    {src: '/static/media/control.3d942b8c91c355921d2c5218f0db09d9.svg', title: `${t('control_title')}`, title_text: `${t('control_text')}`, describtion: `${t('control_context')}`},
  ]

  return (
    <section className='pt-10 mb-10'>
        <div className="container">
            <h2 className='text-4xl font-bold text-logo mb-12 text-center'>{t('advantages')}</h2>
            <div className='flex flex-wrap mb-10 justify-between lg:justify-evenly'>
              {card_data.map((e , i) => (
                <div key={i} className='w-[31%] lg:w-5/12 advantages-card mb-10 text-center relative'>
                  <div className='advantages-card__front border border-solid border-hover'>
                    <div className="flex flex-col hover: p-10 abs absolute top-0 bottom-0 left-0 right-0 z-10">
                      <img className='block mb-5 mr-auto ml-auto' width={72} src={e.src} alt="power icon" />
                      <dl>
                        <dt className='text-xl font-medium text-logo mb-5'>{e.title}</dt>
                        <dd className='text-xl'>{e.title_text}</dd>
                      </dl>
                    </div>
                  </div>
                  <div className='advantages-card__back'>
                    <div className='bg-hover text-white p-10 abc transition-all h-full'>
                      <p className='text-lg'>{e.describtion}</p>
                    </div>
                  </div>
                </div>   
              ))}
            </div>
        </div>
    </section>
  )
}
