import React, { useState, useEffect } from "react";
import useCalcStore from "../../../../zustand/calculator";
import { sendElements } from "../../../../services";
import { useTranslation } from "react-i18next";
import { getDataCenterList } from "../../../../redux/actions/actions";
import { useDispatch, useSelector } from "react-redux";

export default function SelectSection() {
  const dispatch = useDispatch()
  const editStatus = useCalcStore((state) => state.editStatus);
  const calcData = useCalcStore((state) => state.calcData);
  const {dc_address} = useSelector(state => state.dc_address)
  const [total, setTotal] = useState(0);
  const { t } = useTranslation();

  // console.log(calcData.data_center_address)

  const getAmount = async () => {
    try {
      const res = await sendElements("/billing/calculate-colocation", [
        {
          amount: Number(calcData.odf_count),
          data_center: calcData.data_center_address,
          mounting_type: "RACK",
          status: 1,
        },
        {
          amount: Number(calcData.count),
          data_center: calcData.data_center_address,
          mounting_type: "UNIT",
          status: 1,
        },
      ]);
      setTotal(res.err_msg ? res.err_msg : res?.price);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAmount();
  }, [calcData]);

  useEffect(() => {
    dispatch(getDataCenterList());
    editStatus({ data_center_address: "d18235c4-a975-48b1-9d63-32844d6e6f0b", count: 0, odf_count: 0 })
  }, [])

  return (
    <section className="mb-10">
      <div className="flex items-start justify-evenly lg:flex-col">
        <form className=" w-full px-10 py-16 bg-calc-bg rounded-3xl mr-10 mb-5">
          <h3 className="text-xl font-bold mb-12">Co-location</h3>

          <div className="mb-5">
            <label
              htmlFor={"defoult-range"}
              className="flex justify-between font-semibold"
            >
              {t("address")}
            </label>
            <select
              value={calcData?.data_center_address}
              onChange={(e) => editStatus({ data_center_address: e.target.value })}
              id="defoult-range"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            >
              <option>{t("select")}</option>
              {dc_address?.map(el => (
                <option key={el.id} value={el.id}>{el.display_name}</option>
              ))}
            </select>
          </div>

          <div className="mb-5">
            <label
              htmlFor={"defoult-range1"}
              className="flex justify-between font-semibold"
            >
              <p>{t("unit_c")}</p>
              <p>
                {calcData?.count} {t("ta")}
              </p>
            </label>
            <input
              id={"defoult-range1"}
              className="w-full h-1 bg-grey-400 rounded-lg appearance-none cursor-pointer"
              type="range"
              value={calcData?.count}
              name="minValue1"
              min={0}
              max={42}
              onChange={(e) => editStatus({ count: e.target.value })}
            />
            <div className="flex justify-between text-grey-400">
              <p>0</p>
              <p>42</p>
            </div>
          </div>

          <div className="mb-5">
            <label
              htmlFor={"defoult-range1"}
              className="flex justify-between font-semibold"
            >
              <p>{t("rack_c")}</p>
              <p>
                {calcData?.odf_count} {t("ta")}
              </p>
            </label>
            <input
              id={"defoult-range1"}
              className="w-full h-1 bg-grey-400 rounded-lg appearance-none cursor-pointer"
              type="range"
              value={calcData?.odf_count}
              name="minValue1"
              min={0}
              max={42}
              onChange={(e) => editStatus({ odf_count: e.target.value })}
            />
            <div className="flex justify-between text-grey-400">
              <p>0</p>
              <p>42</p>
            </div>
          </div>
        </form>

        <div className="px-10 py-16 bg-calc-bg rounded-3xl w-full">
          <h3 className="text-xl font-bold mb-16">{t("total_sum")}</h3>

          <div className="flex justify-between border-t border-grey-400 py-4">
            <p className="text-lg font-bold">{t("total")}</p>
            <p className={`text-lg font-bold ${(total === "Rack limit out of range" || total === "Unit limit out of range") && "text-[#cf4744]"}`}>
              {total?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} {total !== "Rack limit out of range" && total !== "Unit limit out of range" && t("sum")}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
