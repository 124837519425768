import React, { useEffect, useState } from "react";
import CommonSection from "../../components/common-section/CommonSection";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { sendContact } from "../../redux/actions/actions";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import ConnectionSection from "../../components/connection";
import { ReactComponent as Mail } from "../../assets/connection/mail.svg";
import { ReactComponent as Location } from "../../assets/connection/building.svg";
import { ReactComponent as Phone } from "../../assets/connection/phone.svg";
import { ReactComponent as Time } from "../../assets/connection/work-time.svg";
import { ReactComponent as Calendar } from "../../assets/connection/calendar.svg";
import VideoRekModal from "../../components/modal/videoRekModal";

export default function Connection() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone_number, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!name || !email || !phone_number || !message) {
      toast.error("All input field");
    } else {
      dispatch(
        sendContact({
          full_name: name,
          email,
          phone_number,
          message,
          source: "dc.unicon.uz",
        })
      );
      toast.success("Post sended");
      setInterval(() => navigate("/"), 2000);
    }
  };

  return (
    <>
      <CommonSection title={t("contact")} breadCrumb={t("contact")} />
      <div
        className="container mx-auto pt-24"
        data-aos={"fade-up"}
        data-aos-duration={"2000"}
      >
        <h2 className="text-logo mb-8 text-4xl font-medium">{t("contact")}</h2>

        <div className="mb-24">
          <div className="box py-6 flex gap-14 flex-wrap lg2:gap-7">
            <div className="py-4 px-5 flex w-[30%] gap-5 c-col lg2:w-[45%]">
              <Location className="w-[23px] object-contain h-[23px]" />
              <dl className="flex flex-col gap-3">
                <dt className="font-bold text-lg mb-4">{t("address")}</dt>
                <dd className="text-lg w-72">
                  Toshkent sh, Qorasuv-3, Mingbuloq, 38-uy
                </dd>
              </dl>
            </div>

            <div className="py-4 px-5 flex w-[30%] gap-5 c-col lg2:w-[45%]">
              <Phone className="w-[23px] object-contain h-[23px]" />
              <dl className="flex flex-col gap-3">
                <dt className="font-bold text-lg mb-4">{t("contact")}</dt>
                <dd className="text-lg w-72">+998 71 208 81 94</dd>
              </dl>
            </div>

            <div className="py-4 px-5 flex w-[30%] gap-5 c-col lg2:w-[45%]">
              <Time className="w-[23px] object-contain h-[23px]" />
              <dl className="flex flex-col gap-3">
                <dt className="font-bold text-lg mb-4">{t("work-day")}</dt>
                <dd className="text-lg w-72">{t("work_day")}</dd>
              </dl>
            </div>

            <div className="py-4 px-5 flex w-[30%] gap-5 c-col lg2:w-[45%]">
              <Mail className="w-[23px] object-cover h-[23px]" />
              <dl className="flex flex-col gap-3">
                <dt className="font-bold text-lg mb-4">{t("pochta")}</dt>
                <dd className="text-lg w-72">100187</dd>
              </dl>
            </div>

            <div className="py-4 px-5 flex w-[30%] gap-5 c-col lg2:w-[45%]">
              <Mail className="w-[23px] object-contain h-[23px]" />
              <dl className="flex flex-col gap-3">
                <dt className="font-bold text-lg mb-4">E-mail:</dt>
                <dd className="text-lg w-72">info@unicon.uz</dd>
              </dl>
            </div>

            <div className="py-4 px-5 flex w-[30%] gap-5 c-col lg2:w-[45%]">
              <Calendar className="w-[23px] object-contain h-[23px]" />
              <dl className="flex flex-col gap-3">
                <dt className="font-bold text-lg mb-4">{t("relax")}</dt>
                <dd className="text-lg w-72">{t("relax_day")}</dd>
              </dl>
            </div>
          </div>
        </div>

        <ConnectionSection />
      </div>
      <div className="map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1331.4883954426405!2d69.36445685971903!3d41.33051455128123!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38aef5fab8250075%3A0x928c108393b21d4c!2z0JPQo9CfICJVTklDT04uVVoi!5e0!3m2!1sen!2s!4v1653977855506!5m2!1sen!2s"
          width="100%"
          height="600"
          style={{ border: "0" }}
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="unicon"
        />
      </div>
      <VideoRekModal />
    </>
  );
}
