// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.counter{
    /* https://www.youtube.com/watch?v=UycdSZ3DDDk */
}`, "",{"version":3,"sources":["webpack://./src/pages/Home/components/counter/index.css"],"names":[],"mappings":"AAAA;IACI,gDAAgD;AACpD","sourcesContent":[".counter{\n    /* https://www.youtube.com/watch?v=UycdSZ3DDDk */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
