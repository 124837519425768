import Layout from "../layout";
import { createBrowserRouter } from "react-router-dom";
import Home from "../pages/Home";
import AboutUs from "../pages/about-us";
import Connection from "../pages/Connection";
import Services from "../pages/services";
import Rack from "../pages/tarifs/rack";
import Unit from "../pages/tarifs/unit";
import Select from "../pages/tarifs/select";
import NewsPage from "../pages/news/news";
import Host500 from "../pages/tarifs/host500";
import Host1000 from "../pages/tarifs/host1000";
import Host2000 from "../pages/tarifs/host2000";
import VpsStandart from "../pages/tarifs/vpsStandart";
import VpsPro from "../pages/tarifs/vpsPro";
import VpsSelect from "../pages/tarifs/vpsSelect";
import NewsDetails from "../pages/news/newsDetail";
import Docs from "../pages/documents/documents";

const ErrorPage = () => {
    return <div>Oops! Something went wrong.</div>;
  };

export const routers = createBrowserRouter([
    {
        element: <Layout />,
        errorElement: <ErrorPage />,
        children: [
            {
                element: <Home />,
                path: '/',
            },
            {
                element: <AboutUs />,
                path: '/about-us',
            },
            {
                element: <NewsPage />,
                path: '/news',
            },
            {
                element: <NewsDetails />,
                path: 'news/:id',
            },
            {
                element: <Rack />,
                path: '/rack',
            },
            {
                element: <Unit />,
                path: '/unit',
            },
            {
                element: <Select />,
                path: '/select',
            },
            {
                element: <Host500 />,
                path: '/host-500',
            },
            {
                element: <Host1000 />,
                path: '/host-1000',
            },
            {
                element: <Host2000 />,
                path: '/host-2000',
            },
            {
                element: <VpsStandart />,
                path: '/vps-standart',
            },
            {
                element: <VpsPro />,
                path: '/vps-pro',
            },
            {
                element: <VpsSelect />,
                path: '/vps-select',
            },
            {
                element: <Connection />,
                path: '/connection',
            },
            {
                element: <Services />,
                path: '/services',
            },
            {
                element: <Docs />,
                path: '/docs',
            },
        ]
        
    }
])