import { SWITCH_LANG, SEND_CONTACT, GET_ELEMENT, SEND_ELEMENTS, TYPING, INITIAL_STATE, GET_NEWS, GET_SINGLE_NEWS, GET_DOCS, GET_DATA_CENTER_LIST } from "../constants/constants";
import i18next from "i18next";
import axios from "axios";

export const switchLang = (lng) => async (dispatch) => {
    await i18next.changeLanguage(lng)
    dispatch({
      type: SWITCH_LANG,
      payload: lng
    })
}
  
export const sendContact = (items) => async (dispatch) => {
    try {
      const response = await axios.post('https://back.unicon.uz/api/contact', items)
      dispatch({
        type: SEND_CONTACT,
        payload: response.data
      })
    } catch (e) {
      console.log(e)
    }
}

export const getNews = () => async (dispatch) => {
  try {
    const response = await axios.get(`https://back.unicon.uz/api/cat/dc`);
    dispatch({
      type: GET_NEWS,
      payload: response.data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const getNews6 = () => async (dispatch) => {
  try {
    const response = await axios.get(`https://back.unicon.uz/api/cat/dc?last_count=6`);
    dispatch({
      type: GET_NEWS,
      payload: response.data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const getSingleNews = (id) => async (dispatch) => {
  try {
    const response = await axios.get(
      `https://back.unicon.uz/api/cat/detail/${id}`
    );
    dispatch({
      type: GET_SINGLE_NEWS,
      payload: response.data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const getDocuments = () => async (dispatch) => {
  try {
    const response = await axios.get(`https://back.unicon.uz/api/dc-unicon/dc-documents`);
    dispatch({
      type: GET_DOCS,
      payload: response.data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const getDataCenterList = () => async (dispatch) => {
  try {
    const response = await axios.get(`https://api2.unicon.uz/${i18next.language}/colocation/list/data-center`);
    dispatch({
      type: GET_DATA_CENTER_LIST,
      payload: response.data,
    });
  } catch (e) {
    console.log(e);
  }
};
