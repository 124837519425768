import React, { useEffect, useState } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./index.css";

const renderTime = ({ remainingTime }) => {
  if (remainingTime === 0) {
    return <div className="timer">X</div>;
  }

  return (
    <div className="timer">
      <div className="value">{remainingTime}</div>
    </div>
  );
};

export default function VideoRekModal() {
  const [open, setOpen] = useState(false);
  const [state, setState] = useState(false);
  const hasVisitedBefore = sessionStorage.getItem('visitedBefore2');

  useEffect(() => {
    if (!hasVisitedBefore) {
      sessionStorage.setItem('visitedBefore2', 'true');
      setOpen(true);
      setState(true);
    }
  }, []);

  let last = 0;
  setInterval(tick, 1000);
  function tick() {
    last++;
  }

  const videoRek = document.querySelector(".rek_video")
  // videoRek ? setState(true) : setState(false);


  const handleClick = () => {
    if (videoRek.currentTime >= 0 && last >= 0) {
      setOpen(false);
    }
  };


  return (
    <div
      onClick={handleClick}
      className={
        open === true
          ? "rek-modal w-full fixed top-0 bg-[#fff5] h-full z-[90] flex justify-center items-center cursor-pointer"
          : "hidden"
      }
    >
      <div className="rek-modal__wrapper absolute w-[50%] md:w-[80%] z-[99]">
        <div className="timer-wrapper mb-2">
          <CountdownCircleTimer
            strokeWidth={4}
            size={40}
            isPlaying
            duration={0}
            colors={[["#004777", 0.33], ["#F7B801", 0.33], ["#A30000"]]}
            onComplete={() => [false, 1000]}
          >
            {renderTime}
          </CountdownCircleTimer>
        </div>

        <video className='rek_video h-1/2 w-full object-cover' controls muted={!open} autoPlay={state}>
          <source title="VPS service reklama video (for main page)" className='h-screen' autoPlay={state} src={"https://back.unicon.uz/media/bosh_sahifa/Cloud.mp4"} type="video/mp4" />
        </video>
      </div>
    </div>
  );
}
