import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import CommonSection from '../../components/common-section/CommonSection'
import useCalcStore from '../../zustand/calculator';

export default function Host2000() {
  const {t} = useTranslation();
  const editStatus = useCalcStore(state => state.editStatus)
  const calcData = useCalcStore(state => state.calcData)
  const [total, setTotal]= useState('undefined')

  return (
    <>
        <CommonSection title={'Host select'} breadCrumb={'Host select'} />
        <section className='pt-40 container'>
            <h2 className='text-4xl font-bold text-logo mb-16 text-center'>HOST SELECT {t('tarif_plan')}</h2>
            <p className='text-lg text-center leading-8 mb-16'>{t('tarif_text')}</p>

            <div className="flex items-start justify-evenly lg:flex-col" >
                <form  className=" w-full px-10 py-16 bg-calc-bg rounded-3xl mr-10 mb-5">
                    <h3 className="text-xl font-bold mb-12">Hosting</h3>

                    <div className='mb-5'>
                        <label htmlFor={'defoult-range'} className="flex justify-between font-semibold">
                            <p>Site, MySQL, Mailbox {t('number')}</p>
                            <p>{calcData?.device_count} {t('ta')}</p>
                        </label>
                        <input
                            id={'defoult-range'}
                            className="w-full h-1 bg-grey-400 rounded-lg appearance-none cursor-pointer"
                            type="range"
                            name='minValue'
                            value={calcData?.device_count}
                            min={1}
                            max={20}
                            onChange={(e)=>editStatus({device_count: e.target.value})}
                        />
                        <div className="flex justify-between text-grey-400">
                            <p>1</p>
                            <p>20</p>
                        </div>
                    </div>

                    <div className='mb-5'>
                        <label htmlFor={'defoult-range1'} className="flex justify-between font-semibold">
                            <p>SSD</p>
                            <p>{calcData?.count} MB</p>
                        </label>
                        <input
                            id={'defoult-range1'}
                            className="w-full h-1 bg-grey-400 rounded-lg appearance-none cursor-pointer"
                            type="range"
                            value={calcData?.count}
                            name="minValue1"
                            min={1}
                            max={10000}
                            onChange={(e)=>editStatus({count: e.target.value})}
                        />
                        <div className="flex justify-between text-grey-400">
                            <p>1</p>
                            <p>10 000</p>
                        </div>
                    </div>
                </form>

                <div className="px-10 py-16 bg-calc-bg rounded-3xl w-full">
                    <h3 className="text-xl font-bold mb-16">{t('total_sum')}</h3>

                    <div className="flex justify-between border-t border-grey-400 py-4" >
                        <p className="text-lg font-bold">{t('total')}</p>
                        <p className="text-lg font-bold">{total} {t('sum')}</p>
                    </div>
                </div>
            </div>

            <table className='mr-auto w-full text-lg ml-auto mb-20'>
              <thead className='text-left'>
                <tr className='border-b border-grey-400'>
                  <th className='py-5 pr-20'>{t('tarif_table_h1')}</th>
                  <th className='py-5 pl-20'>{t('amount')}</th>
                </tr>
              </thead>
              <tbody>
                <tr className='border-b border-grey-400'>
                  <td className='py-5 pr-20'>Site</td>
                  <td className='py-5 pl-20'>undefined</td>
                </tr>
                <tr className='border-b border-grey-400'>
                  <td className='py-5 pr-20'>MySQL</td>
                  <td className='py-5 pl-20'>undefined</td>
                </tr>
                <tr className='border-b border-grey-400'>
                  <td className='py-5 pr-20'>Mailbox</td>
                  <td className='py-5 pl-20'>undefined</td>
                </tr>
                <tr className='border-b border-grey-400'>
                  <td className='py-5 pr-20'>SSD</td>
                  <td className='py-5 pl-20'>undefined MB</td>
                </tr>
                <tr className='border-b border-grey-400'>
                  <td className='py-5 pr-20'>Memory</td>
                  <td className='py-5 pl-20'>undefined MB</td>
                </tr>
                <tr className='border-b border-grey-400'>
                  <td className='py-5 pr-20'>TAS-IX ZONE</td>
                  <td className='py-5 pl-20'>+</td>
                </tr>
                <tr className='border-b border-grey-400'>
                  <td className='py-5 pr-20'>{t('1month')}</td>
                  <td className='py-5 pl-20'>undefined {t('sum')}</td>
                </tr>
                <tr className='border-b border-grey-400'>
                  <td className='py-5 pr-20'>{t('3month')}</td>
                  <td className='py-5 pl-20'>undefined {t('sum')} (undefined {t('sum')}/{t('month')})</td>
                </tr>
                <tr className='border-b border-grey-400'>
                  <td className='py-5 pr-20'>{t('6month')}</td>
                  <td className='py-5 pl-20'>undefined {t('sum')} (undefined {t('sum')}/{t('month')})</td>
                </tr>
                <tr className='border-b border-grey-400'>
                  <td className='py-5 pr-20'>{t('1year')}</td>
                  <td className='py-5 pl-20'>undefined {t('sum')} (undefined {t('sum')}/{t('month')})</td>
                </tr>
                <tr className='border-b border-grey-400'>
                  <td className='py-5 pr-20'>{t('free_domen')}</td>
                  <td className='py-5 pl-20'><b>.UZ </b> {t('free_uz')}</td>
                </tr>
              </tbody>
            </table>

            <div className='mb-20 text-center'><a href={'#'} className="bg-logo hover:bg-hover transition-all active:opacity-80 text-xl text-white px-10 font-semibold py-4 rounded-lg">{t('order')}</a></div>

            <h3 className='text-lg font-bold mb-8'>{t('unit_formed_title')}</h3>
            <ul className='text-lg pl-6 mb-16'>
                <li className='list-disc mb-3 font-medium'>{t('unit_formed_item_1')}</li>
                <li className='list-disc mb-3 font-medium'>{t('unit_formed_item_2')}</li>
                <li className='list-disc mb-3 font-medium'>{t('unit_formed_item_3')}</li>
                <li className='list-disc mb-3 font-medium'>{t('unit_formed_item_4')}</li>
                <li className='list-disc mb-3 font-medium'>{t('rack_formed_item_5')}</li>
            </ul>

            <h3 className='text-lg font-bold mb-8'>{t('unit_conditions_title')}</h3>
            <ul className='text-lg pl-6 mb-16'>
                <li className='list-disc mb-3 font-medium'>{t('unit_conditions_item_1')}</li>
                <li className='list-disc mb-3 font-medium'>{t('unit_conditions_item_2')}</li>
                <li className='list-disc mb-3 font-medium'>{t('unit_conditions_item_3')}</li>
                <li className='list-disc mb-3 font-medium'>{t('unit_conditions_item_4')}</li>
                <li className='list-disc mb-3 font-medium'>{t('unit_conditions_item_5')}</li>
            </ul>
        </section>
    </>
  )
}
