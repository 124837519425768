// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h1 {
    font-family: "Roboto", sans-serif;
    text-align: center;
    /* margin-bottom: 40px; */
  }

  .timer-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    transform: translateX(30px);
    /* cursor: pointer; */
  }

  .timer-wrapper svg, .timer-wrapper div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px!important;
    height: 40px!important;
  }

  .timer-wrapper svg > path{
    width: 40px!important;
    height: 40px!important;
    offset: 10%;
  }

  .timer {
    font-family: "Montserrat";
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .text {
    color: #aaa;
  }

  .value {
    font-size: 20px;
  }

  .info {
    max-width: 60px;
    margin: 40px auto 0;
    text-align: center;
    font-size: 16px;
  }

@media screen and (max-width: 450px) {
  .rek-modal__wrapper {
    width: 95%;
  }
  .timer-wrapper {
    transform: translateX(0px);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/modal/index.css"],"names":[],"mappings":"AAAA;IACI,iCAAiC;IACjC,kBAAkB;IAClB,yBAAyB;EAC3B;;EAEA;IACE,aAAa;IACb,yBAAyB;IACzB,mBAAmB;IACnB,2BAA2B;IAC3B,qBAAqB;EACvB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,qBAAqB;IACrB,sBAAsB;EACxB;;EAEA;IACE,qBAAqB;IACrB,sBAAsB;IACtB,WAAW;EACb;;EAEA;IACE,yBAAyB;IACzB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;EACrB;;EAEA;IACE,WAAW;EACb;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,eAAe;IACf,mBAAmB;IACnB,kBAAkB;IAClB,eAAe;EACjB;;AAEF;EACE;IACE,UAAU;EACZ;EACA;IACE,0BAA0B;EAC5B;AACF","sourcesContent":["h1 {\n    font-family: \"Roboto\", sans-serif;\n    text-align: center;\n    /* margin-bottom: 40px; */\n  }\n\n  .timer-wrapper {\n    display: flex;\n    justify-content: flex-end;\n    align-items: center;\n    transform: translateX(30px);\n    /* cursor: pointer; */\n  }\n\n  .timer-wrapper svg, .timer-wrapper div {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    width: 40px!important;\n    height: 40px!important;\n  }\n\n  .timer-wrapper svg > path{\n    width: 40px!important;\n    height: 40px!important;\n    offset: 10%;\n  }\n\n  .timer {\n    font-family: \"Montserrat\";\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n  }\n\n  .text {\n    color: #aaa;\n  }\n\n  .value {\n    font-size: 20px;\n  }\n\n  .info {\n    max-width: 60px;\n    margin: 40px auto 0;\n    text-align: center;\n    font-size: 16px;\n  }\n\n@media screen and (max-width: 450px) {\n  .rek-modal__wrapper {\n    width: 95%;\n  }\n  .timer-wrapper {\n    transform: translateX(0px);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
