import axios from "axios"
// const baseUrl = "https://api2.unicon.uz"
const baseUrl = "https://api2.unicon.uz/uz"

export const sendElements = async (url, data) => {
    try {
      const response = await axios.post(baseUrl + url, data)
      return response.data
    } catch (e) {
      console.log(e)
    }
}