import React, { useEffect, useRef, useState } from "react";
import Sale15 from "../../assets/chegirma15.jpg";
import Sale25 from "../../assets/chegirma25.jpg";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay } from "swiper/modules";
import "./index.css";
import VanillaTilt from "vanilla-tilt";

function Tilt(props) {
  const { options, ...rest } = props;
  const tilt = useRef(null);

  useEffect(() => {
    VanillaTilt.init(tilt.current, options);
  }, [options]);

  return <div ref={tilt} {...rest} />;
}

const renderTime = ({ remainingTime }) => {
  if (remainingTime === 0) {
    return <div className="timer">X</div>;
  }

  return (
    <div className="timer">
      <div className="value">{remainingTime}</div>
    </div>
  );
};

export default function RekModal() {
  const [open, setOpen] = useState(false);
  const hasVisitedBefore = sessionStorage.getItem('visitedBefore');

  useEffect(() => {
    if (!hasVisitedBefore) {
      sessionStorage.setItem('visitedBefore', 'true');
      setOpen(true);
    }
  }, []);

  let last = 0;
  setInterval(tick, 1000);
  function tick() {
    last++;
  }


  const handleClick = () => {
    if ( last >= 0) {
      setOpen(false);
    }
  };


  return (
    <div
      onClick={handleClick}
      className={
        open === true
          ? "rek-modal w-full fixed top-0 bg-[#fff5] h-full z-[90] flex justify-center items-center cursor-pointer"
          : "hidden"
      }
    >
      <div className="rek-modal__wrapper absolute w-[50%] md:w-[80%] z-[99]">
        <div className="timer-wrapper mb-2">
          <CountdownCircleTimer
            strokeWidth={4}
            size={40}
            isPlaying
            duration={0}
            colors={[["#004777", 0.33], ["#F7B801", 0.33], ["#A30000"]]}
            onComplete={() => [false, 1000]}
          >
            {renderTime}
          </CountdownCircleTimer>
        </div>

        <Swiper
          direction={"horizontal"}
          slidesPerView={"auto"}
          spaceBetween={30}
          modules={[Autoplay]}
          loop={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          className="mySwiper w-full"
        >
          <Tilt>
            <SwiperSlide>
              <a target={"_blank"} rel="noreferrer" href={"#"}>
                <img className="w-full" src={Sale15} alt="sale" />
              </a>
            </SwiperSlide>
          </Tilt>
          <Tilt>
            <SwiperSlide>
              <a href="#">
                <img className="w-full" src={Sale25} alt="sale" />
              </a>
            </SwiperSlide>
          </Tilt>
        </Swiper>
      </div>
    </div>
  );
}
