import React from 'react'
import { useTranslation } from 'react-i18next'
import CommonSection from '../../components/common-section/CommonSection'

export default function Host1000() {
  const {t} = useTranslation();

  return (
    <>
        <CommonSection title={'Host 1000'} breadCrumb={'Host 1000'} />
        <section className='pt-40 container'>
            <h2 className='text-4xl font-bold text-logo mb-16 text-center'>HOST 1000 {t('tarif_plan')}</h2>
            <p className='text-lg text-center leading-8 mb-16'>{t('tarif_text')}</p>

            <table className='mr-auto w-full text-lg ml-auto mb-20'>
              <thead className='text-left'>
                <tr className='border-b border-grey-400'>
                  <th className='py-5 pr-20'>{t('tarif_table_h1')}</th>
                  <th className='py-5 pl-20'>{t('amount')}</th>
                </tr>
              </thead>
              <tbody>
                <tr className='border-b border-grey-400'>
                  <td className='py-5 pr-20'>Site</td>
                  <td className='py-5 pl-20'>3</td>
                </tr>
                <tr className='border-b border-grey-400'>
                  <td className='py-5 pr-20'>MySQL</td>
                  <td className='py-5 pl-20'>3</td>
                </tr>
                <tr className='border-b border-grey-400'>
                  <td className='py-5 pr-20'>Mailbox</td>
                  <td className='py-5 pl-20'>3</td>
                </tr>
                <tr className='border-b border-grey-400'>
                  <td className='py-5 pr-20'>SSD</td>
                  <td className='py-5 pl-20'>1000 MB</td>
                </tr>
                <tr className='border-b border-grey-400'>
                  <td className='py-5 pr-20'>Memory</td>
                  <td className='py-5 pl-20'>768 MB</td>
                </tr>
                <tr className='border-b border-grey-400'>
                  <td className='py-5 pr-20'>TAS-IX ZONE</td>
                  <td className='py-5 pl-20'>+</td>
                </tr>
                <tr className='border-b border-grey-400'>
                  <td className='py-5 pr-20'>{t('1month')}</td>
                  <td className='py-5 pl-20'>40 000 {t('sum')}</td>
                </tr>
                <tr className='border-b border-grey-400'>
                  <td className='py-5 pr-20'>{t('3month')}</td>
                  <td className='py-5 pl-20'>114 000 {t('sum')} (38 000 {t('sum')}/{t('month')})</td>
                </tr>
                <tr className='border-b border-grey-400'>
                  <td className='py-5 pr-20'>{t('6month')}</td>
                  <td className='py-5 pl-20'>216 000 {t('sum')} (36 000 {t('sum')}/{t('month')})</td>
                </tr>
                <tr className='border-b border-grey-400'>
                  <td className='py-5 pr-20'>{t('1year')}</td>
                  <td className='py-5 pl-20'>408 000 {t('sum')} (34 000 {t('sum')}/{t('month')})</td>
                </tr>
                <tr className='border-b border-grey-400'>
                  <td className='py-5 pr-20'>{t('free_domen')}</td>
                  <td className='py-5 pl-20'><b>.UZ </b> {t('free_uz')}</td>
                </tr>
              </tbody>
            </table>

            <div className='mb-20 text-center'><a href={'#'} className="bg-logo hover:bg-hover transition-all active:opacity-80 text-xl text-white px-10 font-semibold py-4 rounded-lg">{t('order')}</a></div>

            <h3 className='text-lg font-bold mb-8'>{t('unit_formed_title')}</h3>
            <ul className='text-lg pl-6 mb-16'>
                <li className='list-disc mb-3 font-medium'>{t('unit_formed_item_1')}</li>
                <li className='list-disc mb-3 font-medium'>{t('unit_formed_item_2')}</li>
                <li className='list-disc mb-3 font-medium'>{t('unit_formed_item_3')}</li>
                <li className='list-disc mb-3 font-medium'>{t('unit_formed_item_4')}</li>
                <li className='list-disc mb-3 font-medium'>{t('rack_formed_item_5')}</li>
            </ul>

            <h3 className='text-lg font-bold mb-8'>{t('unit_conditions_title')}</h3>
            <ul className='text-lg pl-6 mb-16'>
                <li className='list-disc mb-3 font-medium'>{t('unit_conditions_item_1')}</li>
                <li className='list-disc mb-3 font-medium'>{t('unit_conditions_item_2')}</li>
                <li className='list-disc mb-3 font-medium'>{t('unit_conditions_item_3')}</li>
                <li className='list-disc mb-3 font-medium'>{t('unit_conditions_item_4')}</li>
                <li className='list-disc mb-3 font-medium'>{t('unit_conditions_item_5')}</li>
            </ul>
        </section>
    </>
  )
}
