import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import CommonSection from "../../components/common-section/CommonSection";
import useVpsCalcStore from "../../zustand/vpsCalc";
import {sendElements} from "../../services";
import {useDispatch, useSelector} from "react-redux";
import {getNews} from "../../redux/actions/actions";
import {Link} from "react-router-dom";

export default function VpsSelect() {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const editStatus = useVpsCalcStore((state) => state.editStatus);
  const calcVps = useVpsCalcStore((state) => state.calcVps);
  const {news} = useSelector((state) => state.news);
  const {location} = useSelector((state) => state.location);

  const type = "yangiliklar?last_count=10";

  const [loader, setLoader] = useState(false)
  const [total, setTotal] = useState(undefined);
  const [prices, setPrices] = useState(undefined);

  const getVps = async () => {
    setLoader(true)
    try {
      const res = await sendElements("/billing/calculate-vps-dc", {
        vms: [
          {
            system_storage: calcVps?.storage_type,
            system_storage_disk: Number(calcVps?.storage_disk),
            cpu: Number(calcVps?.spu),
            ram: calcVps?.ram,
            internet: calcVps?.internet,
            tasix: calcVps?.tasix,
            is_imut: true,
            imut: calcVps?.imut,
            count_vm: 1,
            image_id: "51",
            os_type_id: "4",
            data_disks: [],
            vm_systems: [
              {
                vm_name: "tester",
                ipv_address: true,
              },
            ],
          },
        ],
      });

      setTotal(res?.configurations_total_price);
      setPrices(res);
      setLoader(false)
    } catch (error) {
      setLoader(false)
      console.log(error);
    }
  };

  const handleValidate = () => {
    return !calcVps?.spu || !calcVps?.ram || !calcVps?.storage_type || !calcVps?.storage_disk || !calcVps?.internet || !calcVps?.tasix
  }

  useEffect(() => {
    dispatch(getNews({type}));
  }, []);

  useEffect(() => {
    if (!handleValidate()) {
      getVps().then()
    }
  }, [calcVps, editStatus]);

  return (
    <>
      <CommonSection title={"VPS select"} breadCrumb={"VPS select"}/>
      <section className="container">
        <div className="flex mx-auto py-6">
          <div className="w-1/4 pr-5 pt-5 lg:hidden">
            <div className="sticky top-20">
              <h3 className="text-2xl font-medium text-logo mb-8">
                {t("categories")}
              </h3>

              <div>
                {news &&
                  news?.result?.map((item, idx) => (
                    <Link
                      className="flex min-h-[90px] pb-5 border-b border-[#ccc] mb-5"
                      to={`/news/${item.id}`}
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      <img
                        className="object-cover block h-[70px]"
                        width={"70"}
                        height={"70"}
                        src={`${item?.files[0]?.file}`}
                        alt="image"
                      />
                      <div className="pl-4">
                        <p className="text-logo hover:text-hover text-xs uppercase mb-2">
                          {location === "uz"
                            ? item?.title_uz?.substring(0, 70)
                            : location === "ru"
                              ? item?.title_ru?.substring(0, 70)
                              : item?.title_en?.substring(0, 70)}
                        </p>
                        <div
                          className="text-logo-secondary hover:text-hover text-xs"
                          dangerouslySetInnerHTML={{
                            __html:
                              location === "uz"
                                ? item?.content_uz?.substring(3, 70)
                                : location === "ru"
                                  ? item?.content_ru?.substring(3, 70)
                                  : item?.content_en?.substring(3, 70),
                          }}
                        />
                      </div>
                    </Link>
                  ))}
              </div>
            </div>
          </div>

          <div
            className="card w-3/4 lg:w-full pl-10 pt-5 px-5"
            style={{border: "1px solid #abb8c3"}}
          >
            <h2 className="text-4xl font-bold text-logo mb-16 text-center uppercase">
              VPS select {t("tarif_plan")}
            </h2>
            <p className="text-lg text-center leading-8 mb-16">
              {t("tarif_calc_text1")}
              <br/>
              {t("tarif_calc_text2")}
            </p>

            <div className="flex items-start justify-evenly lg:flex-col">
              <form className=" w-full px-10 py-16 bg-calc-bg rounded-3xl mr-10 mb-5">
                <h3 className="text-xl font-bold mb-12">VPS</h3>

                <div className="mb-5">
                  <label
                    htmlFor={"defoult-range"}
                    className="flex justify-between font-semibold"
                  >
                    <p>vCPU 2.6 GHz</p>
                    {/*<p>{calcVps?.spu}</p>*/}
                  </label>
                  <input
                    id={"defoult-range"}
                    className="w-full py-1 px-2 outline-none rounded-lg appearance-none my-2"
                    type="text"
                    value={calcVps?.spu}
                    onChange={(e) => {
                      const re = /^[0-9\b]+$/;
                      if (e.target.value === '' || re.test(e.target.value)) {
                        editStatus({spu: e.target.value})
                      }
                    }}
                  />
                  {/*<div className="flex justify-between text-grey-400">*/}
                  {/*  <p>2</p>*/}
                  {/*  <p>200</p>*/}
                  {/*</div>*/}
                </div>

                <div className="mb-5">
                  <label
                    htmlFor={"defoult-range"}
                    className="flex justify-between font-semibold"
                  >
                    <p>RAM (GB)</p>
                    {/*<p>{calcVps?.ram} GB</p>*/}
                  </label>
                  <input
                    id={"defoult-range"}
                    className="w-full py-1 px-2 outline-none rounded-lg appearance-none my-2"
                    type="text"
                    value={calcVps?.ram}
                    onChange={(e) => {
                      const re = /^[0-9\b]+$/;
                      if (e.target.value === '' || re.test(e.target.value)) {
                        editStatus({ram: e.target.value})
                      }
                    }}
                  />
                  {/*<div className="flex justify-between text-grey-400">*/}
                  {/*  <p>1</p>*/}
                  {/*  <p>200</p>*/}
                  {/*</div>*/}
                </div>

                <div className="mb-5">
                  <label
                    htmlFor={"defoult-range"}
                    className="flex justify-between font-semibold"
                  >
                    {t("memory_type")}
                  </label>
                  <select
                    value={calcVps?.storage_type}
                    onChange={(e) =>
                      editStatus({storage_type: e.target.value})
                    }
                    id="defoult-range"
                    className="outline-none my-2 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  >
                    <option value="hdd">HDD</option>
                    <option value="ssd">SSD</option>
                  </select>
                </div>

                <div className="mb-5">
                  <label
                    htmlFor={"defoult-range"}
                    className="flex justify-between font-semibold"
                  >
                    <p className="uppercase">{calcVps?.storage_type} (GB)</p>
                    {/*<p>{calcVps?.storage_disk}</p>*/}
                  </label>
                  <input
                    id={"defoult-range"}
                    className="w-full py-1 px-2 outline-none rounded-lg appearance-none my-2"
                    type="text"
                    value={calcVps?.storage_disk}
                    onChange={(e) => {
                      const re = /^[0-9\b]+$/;
                      if (e.target.value === '' || re.test(e.target.value)) {
                        editStatus({storage_disk: e.target.value})
                      }
                    }}
                  />
                  {/*<div className="flex justify-between text-grey-400">*/}
                  {/*  <p>1</p>*/}
                  {/*  <p>10 000</p>*/}
                  {/*</div>*/}
                </div>

                <div className="mb-5">
                  <label
                    htmlFor={"defoult-range1"}
                    className="flex justify-between font-semibold"
                  >
                    <p>Internet (Mb/s)</p>
                    {/*<p>{calcVps?.internet} mb/s</p>*/}
                  </label>
                  <input
                    id={"defoult-range"}
                    className="w-full py-1 px-2 outline-none rounded-lg appearance-none my-2"
                    type="text"
                    value={calcVps?.internet}
                    onChange={(e) => {
                      const re = /^[0-9\b]+$/;
                      if (e.target.value === '' || re.test(e.target.value)) {
                        editStatus({internet: e.target.value})
                      }
                    }}
                  />
                  {/*<div className="flex justify-between text-grey-400">*/}
                  {/*  <p>1</p>*/}
                  {/*  <p>500</p>*/}
                  {/*</div>*/}
                </div>

                <div className="mb-5">
                  <label
                    htmlFor={"defoult-range1"}
                    className="flex justify-between font-semibold"
                  >
                    <p>TAS-IX (Mb/s)</p>
                    {/*<p>{calcVps?.tasix} mb/s</p>*/}
                  </label>
                  <input
                    id={"defoult-range"}
                    className="w-full py-1 px-2 outline-none rounded-lg appearance-none my-2"
                    type="text"
                    value={calcVps?.tasix}
                    onChange={(e) => {
                      const re = /^[0-9\b]+$/;
                      if (e.target.value === '' || re.test(e.target.value)) {
                        editStatus({tasix: e.target.value})
                      }
                    }}
                  />
                  {/*<div className="flex justify-between text-grey-400">*/}
                  {/*  <p>1</p>*/}
                  {/*  <p>1 000</p>*/}
                  {/*</div>*/}
                </div>
              </form>

              <div className="px-10 py-16 bg-calc-bg rounded-3xl w-full">
                {calcVps?.spu > 100 ? (
                  <p className="text-lg font-bold text-hover mb-5">
                    {t("sale25")}
                  </p>
                ) : calcVps?.spu > 10 ? (
                  <p className="text-lg font-bold text-hover mb-5">
                    {t("sale15")}
                  </p>
                ) : (
                  ""
                )}

                {/*{prices != "undefined" && (*/}
                {/*  <table className="mr-auto w-full text-lg ml-auto">*/}
                {/*    <tbody>*/}
                {/*    <tr className="border-b border-grey-400">*/}
                {/*      <td className="py-5 pr-20">vCPU</td>*/}
                {/*      <td className="py-5 pl-20">*/}
                {/*        {prices != undefined &&*/}
                {/*          prices?.configurations_prices[0]?.cpu}{" "}*/}
                {/*        {t("sum")}*/}
                {/*      </td>*/}
                {/*    </tr>*/}
                {/*    <tr className="border-b border-grey-400">*/}
                {/*      <td className="py-5 pr-20">RAM</td>*/}
                {/*      <td className="py-5 pl-20">*/}
                {/*        {prices != undefined &&*/}
                {/*          prices?.configurations_prices[0]?.ram}{" "}*/}
                {/*        {t("sum")}*/}
                {/*      </td>*/}
                {/*    </tr>*/}
                {/*    <tr className="border-b border-grey-400">*/}
                {/*      <td className="py-5 pr-20">INTERNET</td>*/}
                {/*      <td className="py-5 pl-20">*/}
                {/*        {prices != undefined &&*/}
                {/*          prices?.configurations_prices[0]?.internet}{" "}*/}
                {/*        {t("sum")}*/}
                {/*      </td>*/}
                {/*    </tr>*/}
                {/*    <tr className="border-b border-grey-400">*/}
                {/*      <td className="py-5 pr-20">TASIX</td>*/}
                {/*      <td className="py-5 pl-20">*/}
                {/*        {prices != undefined &&*/}
                {/*          prices?.configurations_prices[0]?.tasix}{" "}*/}
                {/*        {t("sum")}*/}
                {/*      </td>*/}
                {/*    </tr>*/}
                {/*    <tr className="border-b border-grey-400">*/}
                {/*      <td className="py-5 pr-20">DISK</td>*/}
                {/*      <td className="py-5 pl-20">*/}
                {/*        {(prices != undefined &&*/}
                {/*            prices?.configurations_prices[0]?.hdd_price) ||*/}
                {/*          prices?.configurations_prices[0]?.ssd_price}{" "}*/}
                {/*        {t("sum")}*/}
                {/*      </td>*/}
                {/*    </tr>*/}
                {/*    <tr>*/}
                {/*      <td className="py-5 pr-20">IP</td>*/}
                {/*      <td className="py-5 pl-20">*/}
                {/*        {prices != undefined &&*/}
                {/*          prices?.configurations_prices[0]?.ipv_address_price}{" "}*/}
                {/*        {t("sum")}*/}
                {/*      </td>*/}
                {/*    </tr>*/}
                {/*    </tbody>*/}
                {/*  </table>*/}
                {/*)}*/}

                <table className="mr-auto w-full text-lg ml-auto">
                  <tbody>
                  <tr>
                    <td className="py-5 pl-20">
                      {Number(prices?.configurations_prices[0]?.cpu) !== 1 && Number(prices?.configurations_prices[0]?.cpu) % 2 !== 0 &&
                        <h4
                          style={{color: 'red', marginBottom: 20}}
                          className={'text'}
                        >
                          Xar bir virtual serverda yadrolar soni juft bo‘lishi lozim
                        </h4>
                      }
                    </td>
                  </tr>
                  <tr className="border-b border-grey-400">
                    <td className="py-5 pr-20">vCPU</td>
                    <td className="py-5 pl-20">
                      {prices !== undefined &&
                        prices?.configurations_prices[0]?.cpu?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}{" "}
                      {t("sum")}
                    </td>
                  </tr>
                  <tr className="border-b border-grey-400">
                    <td className="py-5 pr-20">RAM</td>
                    <td className="py-5 pl-20">
                      {prices !== undefined &&
                        prices?.configurations_prices[0]?.ram?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}{" "}
                      {t("sum")}
                    </td>
                  </tr>
                  <tr className="border-b border-grey-400">
                    <td className="py-5 pr-20">INTERNET</td>
                    <td className="py-5 pl-20">
                      {prices !== undefined &&
                        prices?.configurations_prices[0]?.internet?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}{" "}
                      {t("sum")}
                    </td>
                  </tr>
                  <tr className="border-b border-grey-400">
                    <td className="py-5 pr-20">TASIX</td>
                    <td className="py-5 pl-20">
                      {prices !== undefined &&
                        prices?.configurations_prices[0]?.tasix?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}{" "}
                      {t("sum")}
                    </td>
                  </tr>
                  <tr className="border-b border-grey-400">
                    <td className="py-5 pr-20">DISK</td>
                    <td className="py-5 pl-20">
                      {(prices !== undefined &&
                          prices?.configurations_prices[0]?.hdd_price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')) ||
                        prices?.configurations_prices[0]?.ssd_price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}{" "}
                      {t("sum")}
                    </td>
                  </tr>
                  <tr>
                    <td className="py-5 pr-20">IP</td>
                    <td className="py-5 pl-20">
                      {prices !== undefined &&
                        prices?.configurations_prices[0]?.ipv_address_price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}{" "}
                      {t("sum")}
                    </td>
                  </tr>
                  </tbody>
                </table>

                <div className="flex justify-between border-t border-grey-400 py-4">
                  <p className="text-lg font-bold">{t("total")}</p>
                  <p className="text-lg font-bold">
                    {loader ? 'Hisoblanmoqda...' : `${total === undefined ? '' : total?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} ${t("sum")}`}
                  </p>
                </div>
              </div>
            </div>

            <div className="mb-20 text-center">
              <a
                target="_blank"
                rel="noreferrer"
                href={"http://xizmatlar.unicon.uz/service/18"}
                className="bg-logo hover:bg-hover transition-all active:opacity-80 text-xl text-white px-10 font-semibold py-4 rounded-lg"
              >
                {t("order")}
              </a>
            </div>

            <h3 className="text-lg font-bold mb-8">{t("unit_formed_title")}</h3>
            <ul className="text-lg pl-6 mb-16">
              <li className="list-disc mb-3 font-medium">
                {t("vps_formed_item_1")}
              </li>
              <li className="list-disc mb-3 font-medium">
                {t("vps_formed_item_2")}
              </li>
              <li className="list-disc mb-3 font-medium">
                {t("vps_formed_item_3")}
              </li>
              <li className="list-disc mb-3 font-medium">
                {t("vps_formed_item_4")}
              </li>
              <li className="list-disc mb-3 font-medium">
                {t("vps_formed_item_5")}
              </li>
            </ul>

            <h3 className="text-lg font-bold mb-8">
              {t("unit_conditions_title")}
            </h3>
            <ul className="text-lg pl-6 mb-16">
              <li className="list-disc mb-3 font-medium">
                {t("vps_conditions_item_1")}
              </li>
              <li className="list-disc mb-3 font-medium">
                {t("vps_conditions_item_2")}
              </li>
              <li className="list-disc mb-3 font-medium">
                {t("vps_conditions_item_3")}
              </li>
              <li className="list-disc mb-3 font-medium">
                {t("vps_conditions_item_4")}
              </li>
              <li className="list-disc mb-3 font-medium">
                {t("vps_conditions_item_5")}
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
}
